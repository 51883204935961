@media only screen and (max-width: 600px) {
    html {
        margin: 0;
        height: 100%;
        display: flex;
    }
}

@font-face {
    font-family: 'Bellefair';
    src: local('Bellefair'), url(./fonts/Bellefair-Regular.ttf) format('truetype');
  }

body {
    margin: 0;
    background-color: #000000;
    font-family: 'Bellefair', serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bgcw {
    background: linear-gradient(to right, #bf953f, #fcf6ba, #b38728, #fbf5b7, #aa771c);
    border: 1px solid #d4bf7c;
    border-radius: 50%;
    font-size: 15px;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Baletta', sans-serif;
    width: 80px;
    padding: 30px 15px 10px 15px;
    transform: rotate(350deg);
}

@media only screen and (max-width: 600px) {
    .bgcwcontainer {
        position: absolute;
        top: 110px;
        left: 20px;
    }

    .bgcw {
        font-size: 10px;
        width: 65px;
        padding: 20px 10px 5px 10px;
        font-weight: 600;
    }
}

@media only screen and (min-width: 600px) {
    .bgcwcontainer {
        position: absolute;
        top: 130px;
        left: 50px;
    }

    .bgcw {
        font-size: 20px;
        width: 120px;
    }
}

.info-heading {
    font-size: 18px;
    text-transform: uppercase;
}

.yellow {
    color: rgb(212, 191, 124) !important;
}

.yellow-hr {
    border: 1px solid rgb(212, 191, 124);
}

.question {
    padding-bottom: 5px;

}

.question p:first-of-type {
    font-size: 18px;
    text-transform: uppercase;
    color: rgb(212, 191, 124);
}



